<template>
  <router-link :class="classes" :to="to" v-if="type === 'router-link'">
    <slot></slot>
  </router-link>
  <a
    v-else-if="type === 'external-link'"
    :class="classes"
    :href="to"
    target="_blank"
    rel="noopener noreferrer"
  >
    <slot></slot>
  </a>
  <button v-else :type="type" :class="classes">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: "button",
    },
    to: {
      type: String,
      required: false,
    },
    classes: String,
  },
};
</script>

<style lang="scss" scoped>
button,
a {
  @apply md:hover:opacity-80 transition-opacity w-max p-3 text-xl;
  font-family: "Open Sans", Arial, Helvetica, sans-serif !important;
}

.dark-btn {
  @apply rounded-lg text-white min-w-20 block;
  background: #3a3a3a;
}

.red-btn {
  @apply border-lightRed text-lightRed border-2 rounded-md py-1 px-5;
  filter: drop-shadow(0px 4px 4px rgba(241, 86, 66, 0.25));
}

.light-btn {
  @apply rounded-lg min-w-20 text-black bg-white block;
}
</style>
